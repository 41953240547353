import * as Sentry from '@sentry/react'
import React, { Suspense } from 'react'
import { i18nInit } from '@/features/i18n'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { store } from '@/app'
import { createRoot } from 'react-dom/client'
import './index.css'
import { OpenFeatureClientProvider } from './incubator/featureFlags/FeatureFlagProvider'
import { FlagdWebProvider } from '@openfeature/flagd-web-provider'
import { Provider } from 'react-redux'
import { Loading } from './features/loading'

import { PlanAMobileApp } from '@/shell/mobile'
import { PlanADesktopApp } from '@/shell/desktop'
import { isDesktop } from './app/platform'
import { replaySessionSampleRate } from '@/app/configuration'

const App = isDesktop() ? PlanADesktopApp : PlanAMobileApp

i18nInit()

const flagProvider = new FlagdWebProvider({
  host: import.meta.env.VITE_REACT_APP_FLAG_SERVER,
  port: 443,
  tls: true,
  maxRetries: 10,
  maxDelay: 30000,
})
console.log(replaySessionSampleRate)
Sentry.init({
  dsn: import.meta.env.VITE_REACT_APP_SENTRY_DSN,
  environment: import.meta.env.MODE + '-' + import.meta.env.VITE_PLATFORM,
  release: process.env.gitVersion ?? '' + process.env.gitCommitHash ?? '',
  autoSessionTracking: true,
  enableTracing: true,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: replaySessionSampleRate,
  beforeBreadcrumb: breadcrumb => {
    if (breadcrumb && breadcrumb.category === 'fetch') {
      const method = breadcrumb.data?.method
      const url = breadcrumb.data?.url
      const status = breadcrumb.data?.status_code
      if (method === 'GET' && url?.includes('since=') && status === 200) {
        return null
      }
    }
    return breadcrumb
  },
})

const persistor = persistStore(store)

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <OpenFeatureClientProvider provider={flagProvider}>
          <Suspense fallback={<Loading />}>
            <App />
          </Suspense>
        </OpenFeatureClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)
